import $ from "jquery";
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const $langs = $(`link[hreflang]`)
const $nav = $('ul.navbar-nav')

function changeTo(lang: string) {
  const redirectTo = $(`link[hreflang='${lang}']`).attr('href')
  if(redirectTo) {
    i18next.changeLanguage(lang).then(() => window.location.pathname = redirectTo)
  }
}

i18next
.use(LanguageDetector)
.init({})
.then(() => {
  if(i18next.language.slice(0,2) != document.documentElement.lang) {
    changeTo(i18next.language.slice(0,2))
  }
})
.then(() => {
  $langs.each((index, link: HTMLLinkElement) => {
    if(link.hreflang == 'x-default') {
      return
    } else if(link.hreflang == i18next.language.slice(0,2)) {
      $nav.append(`<li class="nav-item">
    <span class="active nav-link" data-lang="${link.hreflang}" href="${link.href}">${link.hreflang.toUpperCase()}</span>
  </li>`)
    } else {
      $nav.append(`<li class="nav-item">
    <a class="nav-link" data-lang="${link.hreflang}" href="${link.href}">${link.hreflang.toUpperCase()}</a>
  </li>`)
    }
  })

  $("[data-lang]").click((ev) => {
    ev.preventDefault()
    const lang = ev.target.dataset['lang']
    changeTo(lang)
  })
});