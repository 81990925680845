import $ from "jquery";
import './i18n'
import 'popper.js'
import 'bootstrap'
// import './ts/rescale'
// import './ts/backend'
import ContactForm from './ts/formspee'
import Animation from './ts/animation2'
import animCameraData from './assets/dep_v3_00000.json'

const $window = $(document);
const $nav = $('#top-nav');
const $header = $('#lottie-anim').parent()

$window.on('scroll', function(){
  const scrollTop = $window.scrollTop();
  $nav.toggleClass('hidden', scrollTop < 500);
});

// const cameraAnim = new Animation('#lottie-anim', animCameraData, 519)
// const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
// if(!connection || connection.downlink > 10) {
//   const cameraAnim = new Animation('#lottie-anim', animCameraData, 0, 519, '#munkaink')
// }
const contact = new ContactForm('#contact-form')