export default class Formspee {
  formSelector: string
  //errorBox: string
  form: HTMLFormElement

  constructor(formSelector) {
    this.formSelector = formSelector
    //this.errorBox = errorBox
    this.form = document.querySelector(this.formSelector)
    this.setup()
  }

  setup() {
    if(!this.form) {
      console.error('Form does not exist', this.formSelector)
      return
    }

    this.form.addEventListener('submit', this.submit.bind(this))
  }

  submit(ev: Event) {
    ev.preventDefault()
    
    const button: HTMLButtonElement = document.querySelector(`${this.formSelector} button`)
    button.disabled = true
    button.innerHTML = 'Küldés ...'

    const data = new FormData(this.form)
    if(data.get('last-name')) {
      this.form.reset()
      return this.showError(new Error('Not allowed'))
    }

    this.send(data)
    .then(this.showSuccess.bind(this))
    .catch(this.showError.bind(this))
    this.form.reset()
  }

  showSuccess() {
    const button: HTMLButtonElement = document.querySelector(`${this.formSelector} button`)
    button.innerHTML = 'Elküldve'
    button.classList.add('btn-success')
    button.disabled = true
  }

  showError(err: Error) {
    const button: HTMLButtonElement = document.querySelector(`${this.formSelector} button`)
    button.innerHTML = `Hiba: ${err.message}`
    button.classList.add('btn-warning')
    button.disabled = false
  }

  async send(data: FormData) {
    const response = await window.fetch(this.form.action, {
      method: this.form.method,
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      body: JSON.stringify({
        name: data.get('name'),
        email: data.get('email'),
        message: data.get('message'),
      })
    })
    if(response.status == 200) {
      return ''
    }
    const error = await response.json()
    throw new Error(error.error)
  }
}